<template>
  <b-container class="container mt-2">
    <h1>OpenTherm</h1>

    <div class="row mb-3">

      <div class="col-auto mb-3">
        <BoilerStateControl
            :boiler-online="boilerStateItems.data.boilerOnline"
            :heating-mode="boilerStateItems.data.heatingMode"
            :central-heating="boilerStateItems.data.centralHeating"
            :hot-water="boilerStateItems.data.hotWater"
            :cooling="boilerStateItems.data.cooling"
            :flame="boilerStateItems.data.flame"
            :fault="boilerStateItems.data.fault"
            :diagnostic="boilerStateItems.data.diagnostic"
            :up-to-date="boilerStateItems.isUpToDate"
            :last-update=boilerStateItems.updatedAtPretty
        />
      </div>

      <div class="col-auto mb-3">
        <BoilerParamsControl
            title="Primary parameters"
            :items="primaryParamsItems.data"
            :up-to-date="primaryParamsItems.isUpToDate"
            :last-update="primaryParamsItems.updatedAtPretty"
        />
      </div>

      <div class="col-auto mb-3">
        <BoilerParamsControl
            title="Auxiliary parameters"
            :items="auxParamsItems.data"
            :up-to-date="auxParamsItems.isUpToDate"
            :last-update="auxParamsItems.updatedAtPretty"
        />
      </div>

      <div class="col-auto mb-3">
        <BoilerParamsControl
            title="Statistics"
            :items="statsItems.data"
            :up-to-date="statsItems.isUpToDate"
            :last-update="statsItems.updatedAtPretty"
        />
      </div>
    </div>

    <h3>Commands</h3>
    <OpenThermCommandsControl/>
  </b-container>
</template>


<script>
import BoilerStateControl from "@/components/opentherm/BoilerStateControl";
import BoilerParamsControl from "@/components/opentherm/BoilerParamsControl";
import OpenThermCommandsControl from "@/components/opentherm/OpenThermCommandsControl";
import axios from "axios";
import {DateTime, Duration} from "luxon";
import {showAxiosErrorToast} from "@/util/toasts";
import {timeIntervalFromPretty} from "@/util/datetime";

export default {
  name: 'OpenThermPage',

  components: {
    OpenThermCommandsControl,
    BoilerStateControl,
    BoilerParamsControl,
  },

  data: function() {
    return {
      boilerStateItems: {
        data: {
          boilerOnline: false,
          heatingMode: '',
          centralHeating: false,
          hotWater: false,
          cooling: false,
          flame: false,
          fault: false,
          diagnostic: false,
        },
        updatedAt: 0,
        updatedAtPretty: '',
        isUpToDate: false,
        maxFreshAge: Duration.fromObject({ seconds: 10 }),
      },

      primaryParamsItems: {
        data: {
          modulation_lvl: {name: "Modulation level", unit: "%", value: '', decimalPlaces: 1},
          boiler_temp: {name: "Boiler temperature", unit: "℃", value: '', decimalPlaces: 1},
          return_temp: {name: "Return temperature", unit: "℃", value: '', decimalPlaces: 1},
          dhw_temp: {name: "Hot water temperature", unit: "℃", value: '', decimalPlaces: 1},
          dhw_flow_rate: {name: "Hot water flow rate", unit: "l/m", value: '', decimalPlaces: 2},
        },
        updatedAt: 0,
        updatedAtPretty: '',
        isUpToDate: false,
        maxFreshAge: Duration.fromObject({ minutes: 6 }),
      },

      auxParamsItems: {
        data: {
          water_pressure: {name: "Water pressure", unit: "B", value: '', decimalPlaces: 3},
          exhaust_temp: {name: "Exhaust temperature", unit: "℃", value: '', decimalPlaces: 1},
          dhw_setpoint: {name: "Hot water setpoint", unit: "℃", value: '', decimalPlaces: 0},
          max_ch_setpoint: {name: "Max heating setpoint", unit: "℃", value: '', decimalPlaces: 0},
        },
        updatedAt: 0,
        updatedAtPretty: '',
        isUpToDate: false,
        maxFreshAge: Duration.fromObject({ minutes: 22 }),
      },

      statsItems: {
        data: {
          burner_starts: {name: "Burner starts", unit: "", value: '', decimalPlaces: 0},
          ch_pump_starts: {name: "CH pump starts", unit: "", value: '', decimalPlaces: 0},
          dhw_pump_valve_starts: {name: "DHW pump/valve starts", unit: "", value: '', decimalPlaces: 0},
          dhw_burner_starts: {name: "DHW burner starts", unit: "", value: '', decimalPlaces: 0},
          burner_op_hours: {name: "Burner run", unit: "hrs", value: '', decimalPlaces: 0},
          ch_pump_op_hours: {name: "CH pump run", unit: "hrs", value: '', decimalPlaces: 0},
          dhw_pump_valve_op_hours: {name: "DHW pump/valve run", unit: "hrs", value: '', decimalPlaces: 0},
          dhw_burner_op_hours: {name: "DHW burner run", unit: "hrs", value: '', decimalPlaces: 0},
        },
        updatedAt: 0,
        updatedAtPretty: '',
        isUpToDate: false,
        maxFreshAge: Duration.fromObject({ minutes: 22 }),
      },

      refreshTimer: null
    }
  },

  created: function() {
    this.refreshData();
  },

  destroyed: function() {
    this.cancelAutoRefresh();
  },

  methods: {
    refreshData: function() {
      axios.get('/api/opentherm/status-full')
        .then(response => {
          this.updateStateControl(response.data.controllerStatus, response.data.boilerStatus);
          this.updateParamsControl(response.data.boilerPrimaryParams, this.primaryParamsItems);
          this.updateParamsControl(response.data.boilerSecondaryParams, this.auxParamsItems);
          this.updateParamsControl(response.data.boilerStats, this.statsItems);
        })
        .catch(err => {
          showAxiosErrorToast("Failed to fetch OpenTherm status", err);
        })
        .finally(() => {
          let now = DateTime.now();
          this.updateFreshness(this.boilerStateItems, now);
          this.updateFreshness(this.primaryParamsItems, now);
          this.updateFreshness(this.auxParamsItems, now);
          this.updateFreshness(this.statsItems, now);
        })
      this.refreshTimer = setTimeout(this.refreshData, 2000);
    },

    updateStateControl: function(freshControllerStatus, freshBoilerStatus) {
      if (!freshControllerStatus || !freshControllerStatus.data) {
        return false;
      }

      this.boilerStateItems.data.boilerOnline = freshControllerStatus.data.boiler_online;
      this.boilerStateItems.data.heatingMode = freshControllerStatus.data.mode;
      this.boilerStateItems.updatedAt = DateTime.fromSeconds(freshControllerStatus.updatedAt);

      if (this.boilerStateItems.data.boilerOnline) {
        if (freshBoilerStatus && freshBoilerStatus.data) {
          this.boilerStateItems.data.centralHeating = freshBoilerStatus.data.ch;
          this.boilerStateItems.data.hotWater = freshBoilerStatus.data.dhw;
          this.boilerStateItems.data.cooling = freshBoilerStatus.data.cooling;
          this.boilerStateItems.data.flame = freshBoilerStatus.data.flame;
          this.boilerStateItems.data.fault = freshBoilerStatus.data.fault;
          this.boilerStateItems.data.diagnostic = freshBoilerStatus.data.diag;
        }
      } else {
        this.boilerStateItems.data.centralHeating = false;
        this.boilerStateItems.data.hotWater = false;
        this.boilerStateItems.data.cooling = false;
        this.boilerStateItems.data.flame = false;
        this.boilerStateItems.data.fault = false;
        this.boilerStateItems.data.diagnostic = false;
      }

    },

    updateParamsControl: function(freshData, localData) {
      if (!freshData || !localData) {
        return;
      }
      for (const [key, value] of Object.entries(freshData.data)) {
        let localDataEntry = localData.data[key];
        if (!localDataEntry) {
          continue;
        }
        localDataEntry.value = value.toFixed(localDataEntry.decimalPlaces);
      }
      localData.updatedAt = DateTime.fromSeconds(freshData.updatedAt);
    },

    updateFreshness: function(dataObject, now) {
      if (dataObject.updatedAt) {
        dataObject.updatedAtPretty = timeIntervalFromPretty(dataObject.updatedAt, now);
        dataObject.isUpToDate = now.diff(dataObject.updatedAt).as('seconds') <= dataObject.maxFreshAge.as('seconds');
      } else {
        dataObject.updatedAtPretty = 'no data';
        dataObject.isUpToDate = false;
      }

    },

    cancelAutoRefresh: function() {
      clearTimeout(this.refreshTimer);
    }
  }
}
</script>


<style scoped>
</style>