<template>
  <div>

    <div class="d-flex align-items-center gap-1">
      <h2>Zigbee - network topology</h2>
      <RefreshButton variant="outline-success" :loading="loading" @click="regenerateTopology" />
    </div>

    <b>Last updated:</b> {{updatedAt | toLocalDateTime}}<br>

    <!--
    NOTE: when using <img>, SVG images are not interactive. It means that there will be no tooltips.
    However, for Zigbee Network Topology, tooltips contain some useful information (e.g. nodes addresses).
    To make it work, it's better to use <object> tag, but MSW does not work properly with it.
    As a workaround, here we check current environment and use <img> in dev and <object> in prod.

    It might be better to do it this way (in this case a workaround with X-Frame-Options=SAMEORIGIN would not
    be required as well:

    <template>
      <div v-html="svgContent"></div>
    </template>

    <script setup>
    import { ref, onMounted } from 'vue'

    const svgContent = ref('')

    onMounted(async () => {
      try {
        const response = await fetch('/path-to-your.svg')
        svgContent.value = await response.text()
      } catch (error) {
        console.error('Failed to load SVG', error)
      }
    })
    </script>
    -->
    <object v-if="isProdEnv" :data="imgUrl"></object>
    <img v-else :src="imgUrl" alt="Topology" />

    <div class="mb-5"></div>
  </div>
</template>


<script>
import {DateTime} from "luxon";
import axios from "axios";
import {showAxiosErrorToast, showErrorToast} from "@/util/toasts";
import RefreshButton from "@/components/common/RefreshButton";

const imgUrlBase = "/api/zigbee/topology/svg?randomVal=";
const maxWaitForNewTopologyAttempts = 15;
const checkForNewTopologyInterval = 1500;

export default {
  name: 'ZigbeeNetworkTopologyControl',
  props: {},
  components: {RefreshButton},

  data: function() {
    return {
      updatedAt: 1669671896,
      imgUrl: imgUrlBase + '0',
      loading: true,
      waitForNewTopologyAttempts: null,
    }
  },

  filters: {
    toLocalDateTime: function(value) {
      if (value) {
        return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      }
    },
  },

  created: function() {
    axios.get('/api/zigbee/topology/metadata')
      .then(response => this.updatedAt = response.data.lastUpdatedEpoch)
      .catch(error => showAxiosErrorToast("Failed to fetch zigbee topology metadata", error))
      .finally(() => this.loading = false);
  },

  computed: {
    isProdEnv: function() {
      console.log(process.env.NODE_ENV);
      return process.env.NODE_ENV === 'production';
    }
  },

  methods: {
    regenerateTopology: function() {
      this.loading = true;

      axios.post('/api/zigbee/topology/request-update')
        .then(() => {
          this.waitForNewTopologyAttempts = 0;
          setTimeout(this.waitForNewTopology, checkForNewTopologyInterval);
        })
        .catch(error => showAxiosErrorToast("Failed to request zigbee topology regeneration", error))
    },

    waitForNewTopology: async function() {
      ++this.waitForNewTopologyAttempts;
      let response = null;
      try {
        response = await axios.get('/api/zigbee/topology/metadata');
      } catch (error) {
        console.warn("Failed to fetch zigbee topology metadata", error)
      }
      if (response && response.data && response.data.lastUpdatedEpoch !== this.updatedAt) {
        this.updatedAt = response.data.lastUpdatedEpoch;
        this.imgUrl = imgUrlBase + this.updatedAt;
        this.loading = false;
      } else {
        if (this.waitForNewTopologyAttempts > maxWaitForNewTopologyAttempts) {
          showErrorToast("Failed to get updated zigbee network topology");
          this.loading = false;
        } else {
          setTimeout(this.waitForNewTopology, checkForNewTopologyInterval);
        }
      }
    },

  }
}
</script>


<style scoped>
.gap-1 {
  gap: 1em;
}
</style>