<template>
  <b-overlay :show="showOverlay" rounded="sm" spinner-variant="primary" spinner-type="grow">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="mb-0">💡 Light </h5>
          <b-button variant="outline-danger" size="sm" @click="turnOffEverywhere">Turn off all</b-button>
        </div>
      </template>

      <div v-for="lightSource in lights.lightSources" v-bind:key="lightSource.locationDisplayName" class="mb-3">
        {{lightSource.locationDisplayName}}:
        <div v-for="light in lightSource.lights" v-bind:key="light.id">
          <LightSourceControl
              :show-overlay="postingUpdate"
              :id="light.id"
              :display-name="light.displayName"
              :is-rgb="light.isRgb"
              :turn-off-at="light.turnOffAt"
              :state.sync="light.state"
              :level.sync="light.level"
              :disco.sync="light.disco"
              @turn-on-off="onTurnOnOff"
              @brightness-change="onBrightnessChange"
              @set-color-xy="onSetColorXY"
              @set-color-rgb="onSetColorRGB"
              @disco-mode-change="onDiscoModeChange"
          />
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>


<script>
import LightSourceControl from "@/components/lights/LightSourceControl";
import axios from 'axios'
import {showAxiosErrorToast} from "@/util/toasts";

export default {
  name: 'LightsControl',
  components: {
    LightSourceControl,
  },
  props: {
    lightsStatus: Object,
  },

  data: function () {
    return {
      lights: [],
      postingUpdate: false,
    }
  },

  computed: {
    showOverlay: function () {
      return this.postingUpdate || this.lights.length === 0;
    }
  },

  watch: {
    lightsStatus: function() {
      if (!this.postingUpdate) {
        this.lights = this.lightsStatus;
      }
    }
  },

  methods: {
    async turnOffEverywhere() {
      await this.postRequest('/api/light_control/on_off/by_location/Everywhere/Off');
      this.$emit("force-update");
    },

    async onTurnOnOff(id, state) {
      await this.postRequest('/api/light_control/on_off/by_device_id/' + id + "/" + state);
    },

    async onBrightnessChange(id, level) {
      await this.postRequest('/api/light_control/level/by_device_id/' + id + "/" + level);
    },

    async onSetColorXY(id, x, y) {
      await this.postRequest('/api/light_control/color_xy/' + id, { x: x, y: y });
    },

    async onSetColorRGB(id, r, g, b) {
      await this.postRequest('/api/light_control/color_rgb/' + id, { r: r, g: g, b: b });
    },

    async onDiscoModeChange(id, state) {
      await this.postRequest('/api/light_control/disco/' + id + '/' + state);
    },

    async postRequest(url, queryParams) {
      this.postingUpdate = true;
      try {
        return await axios.post(url, null, { params: queryParams });
      } catch(error) {
        showAxiosErrorToast("Failed to change light state", error);
      } finally {
        this.postingUpdate = false;
      }
      return null
    }
  }

}
</script>


<style scoped>
</style>